<template>
  <b-sidebar
    id="add-new-skud-sidebar"
    :visible="isAddNewSkudSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-skud-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('skuds.add_new_skud') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
          >
            <b-form-group
              :label="$t('skuds.skud_name')"
              label-for="name"
            >
              <b-form-input
                id="full-name"
                v-model="skudData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Skudname -->
          <validation-provider
            #default="validationContext"
            name="number"
            rules="required"
          >
            <b-form-group
              :label="$t('skuds.number')"
              label-for="number"
            >
              <b-form-input
                id="num"
                v-model="skudData.number"
                :state=" resOk.number.success ? false : getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}{{ resOk.number.error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="card"
          >
            <b-form-group
              :label="$t('skuds.card')"
              label-for="card"
            >
              <b-form-input
                id="card"
                v-model="skudData.card"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Owner"
          >
            <b-form-group
              :label="$t('skuds.owner')"
              label-for="owner"
            >
              <b-form-input
                id="owner"
                v-model="skudData.owner"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Version -->
          <validation-provider
            #default="validationContext"
            name="Credit"
            rules="integer"
          >
            <b-form-group
              :label="$t('skuds.credit')"
              label-for="credit"
            >
              <b-form-input
                id="credit"
                v-model="skudData.credit"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Version -->
          <validation-provider
            #default="validationContext"
            name="Promo"
            rules="integer"
          >
            <b-form-group
              :label="$t('skuds.promo')"
              label-for="promo"
            >
              <b-form-input
                id="promo"
                v-model="skudData.promo"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Auto"
          >
            <b-form-group
              :label="$t('skuds.auto')"
              label-for="auto"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="skudData.auto"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="auto"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Autoamount"
            rules="integer"
          >
            <b-form-group
              :label="$t('skuds.autoamount')"
              label-for="autoamount"
            >
              <b-form-input
                id="autoamount"
                v-model="skudData.autoamount"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="Company"
            rules="required"
          >
            <b-form-group
              :label="$t('skuds.company')"
              label-for="company"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="skudData.company_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companyOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="company"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Skud Role -->
          <validation-provider
            #default="validationContext"
            name="Object"
          >
            <b-form-group
              :label="$t('skuds.location')"
              label-for="object"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="skudData.object_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="objectOptions.filter(item => item.company === skudData.company_id)"
                :reduce="val => val.value"
                :clearable="true"
                input-id="object"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('common.add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('common.cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSkudSidebarActive',
    event: 'update:is-add-new-skud-sidebar-active',
  },
  props: {
    isAddNewSkudSidebarActive: {
      type: Boolean,
      required: true,
    },
    objectOptions: {
      type: Array,
      required: true,
    },
    companyOptions: {
      type: Array,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const blankSkudData = {
      name: '',
      card: '',
      number: '',
      owner: '',
      credit: 0,
      autoamount: 0,
      promo: 0,
      company_id: null,
      object_id: null,
      status: 'inactive',
      accepted: true,
    }

    const skudData = ref(JSON.parse(JSON.stringify(blankSkudData)))
    const resetskudData = () => {
      skudData.value = JSON.parse(JSON.stringify(blankSkudData))
    }

    const ok = {
      number: {
        success: null,
        error: '',
      },
    }

    const resOk = ref({})
    resOk.value = ok

    const onSubmit = () => {
      resOk.value = ok
      store.dispatch('app-skud/addSkud', skudData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-skud-sidebar-active', false)
        })
        .catch(e => {
          // console.log(e)
          if (typeof e === 'object') {
            if (e.status === 400 && e.data && e.data.error) {
              Object.keys(e.data.error).forEach(err => {
                if (e.data.error[err]) {
                  resOk.value[err].success = true
                  resOk.value[err].error = e.data.error[err].join('; ')
                  // console.log(resOk)
                  // eslint-disable-next-line
                  // alert(`${err}: ${e.data.error[err].join('; ')}`)
                }
              })
            }
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetskudData)

    return {
      skudData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resOk,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-skud-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
